/* eslint-env browser */
import React, { Component } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);

class Thanks extends Component {
  getContent(location) {
    if (!location.state) {
      return 'Thank you!';
    }
    const contentLookup = {
      email:
        'Thank you for your email. We will get back to you as soon as possible.',
      'email-list':
        'Thank you for your email. We will get back to you as soon as possible.',
      reviews:
        'Thank you. Your review has been submitted, and is in moderation. It will appear once approved.',
    };

    return contentLookup[location.state.mode];
  }

  render() {
    const content = this.getContent(this.props.location);

    return (
      <InfoPage
        title="Jennifer Mone Hill Artist - Contact Thanks"
        description="Thanks for contacting Jennifer Mone Hill Artist."
        keywords={[
          'Jennifer Moné Hill Artist',
          'Jen Hill',
          'Jennifer Moné Hill',
          'Jen Hill Artist',
          'Jennifer Mone Hill',
        ]}
        imageLink="/works/Spectrum"
        imgSrc="Spectrum-by-Jennifer-Mone-Hill-800.jpg"
        imgSrc2x="Spectrum-by-Jennifer-Mone-Hill-1600.jpg"
        alt="Spectrum by Jennifer Moné Hill"
      >
        <ContentBox>
          <h3>{content}</h3>
        </ContentBox>
      </InfoPage>
    );
  }
}

Thanks.propTypes = {
  location: PropTypes.object,
};

export default Thanks;
